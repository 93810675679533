@import url('https://fonts.googleapis.com/css2?family=Comforter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap');

.construct {
    display: flex;
}

.construction {
    height: 250px;
    width: 250px;
    margin: 0 auto;
}

.universal{
    background-color: #fff;
}
.entire{
    margin-bottom: 100px;
}
/* nav */
.main-color {
    background-color: #055;
}
.nav-items, .nav-items:hover {
    color: #fff;
    font-size:x-large;
    text-decoration: none;
    padding: 10px;
}
.nav-main {
    font-size:60px;
    color: #fff;
    font-family: "Comforter", cursive;
}
.size-images {
    height: 400px;
    width: 315px;
    margin: 20px;
    border: 2px solid #a079eb;
  }
.title{
    text-align: center;
    margin-top: 20px;
    color: #6121dd;
}
.style-projects {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

}
.background-style {
    background: linear-gradient(#d4c3f6, #b597ef, #d4c3f6);
    margin: 0 auto;
    height: 545px;
    border: 2px solid #a079eb;
  }
.live-button{
    background-color: #6121dd;
    color: #fff;
    padding: 5px;
    margin: 5px;
    height: 40px;
    font-size: 17px;
}
.anchor-button{
    display: block;
    text-align: center;
}
.showcase {
    width: 300px;
    text-align: center;
    margin-left: 20px;
    color: #6121dd;
    font-size: large;
    font-weight: 700;
}
/* about */
.card-katie{
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 20px;
    width: 500px;
    border: 5px solid #055;
    border-right: 10px solid #055;
    border-top: 10px solid #055;
    border-bottom: 10px solid #055;
    margin: 0 auto;
}
.headshot{
    height: 290px;
    width: 250px;
    border-left: 5px solid #055;
    border-right: 10px solid #055;
    grid-column: 1/2;
}
.card-items{
    grid-column: 2/2;
    margin-top: 70px;
}

.name{
    color: #331275;
    font-family: "Comforter", cursive;
    font-weight: 600;
    font-size: 45px;
}

.contact-info:hover{
    color: #099;
    text-decoration: none;
    cursor: pointer;

}
.brand{
    background-image: url("https://www.ppt-backgrounds.net/thumbs/subtle-swirl-template-margins-blue-style-backgrounds.jpg");
    text-align: center;
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    color: #6121dd;
    padding-top: 10px;
    opacity: 0.8;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 100px;
}
.blurb, .blurb-title{
    font-size: 20px;
    width: 70%;
    margin: 0 auto;
    padding-bottom: 25px;
    font-weight: 700;
}
.blurb-title{
    font-size: 30px;
}
.skill-box{
    display: inline-grid;
    width: 140px;
    margin: 10px;
}
.logo-img{
    height: 100px;
    width: 100px;
    margin: 0 auto;
    padding: 7px;

}
.tech-name{
    font-size: 15px;
    margin: 0 auto;
    color: #6121dd;
    font-weight: 700;

}
.full-page{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}
.logo-box{
    display: flex;
    justify-content: space-around;
    background-color: #f4effd;
    padding: 4px;
}
.logos{
    width: 100%;
    height: 85px;
    padding: 5px;
    border: 2px solid #fff;
}


.hamburger{
    height: 45px;
    width: 45px;
}
.skills{
    font-size: 50px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Bungee Shade', cursive;
    color: #e3b500
}
.foot{
    background-color: #055;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-top: 10px;
}
.foot-text, .foot-text-hover{
    display: inline;
    padding-right: 15px;
    padding-left: 10px;
    color: #f4effd;
    font-size: 16px;
}
.foot-text-hover:hover{
    color: #a079eb;
    text-decoration: none;
    cursor: pointer;
}
.nav-link-katie:hover{
    color: #a079eb;
    text-decoration: none;
    cursor: pointer;
}
.link-buttons{
    display: flex;
    justify-content: center;
}
.software {
    font-size: 16px;
    color: #055;
}
.recognition{
    border: 5px solid #331275
}
.recognition-text{
    color: #e3b500;
    font-weight: 900;
}